import React, { useRef, useEffect } from 'react';
import './Header.scss';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-link';
import Button from '../button/Button';
import Logo from '../../images/logo_new.png';
import useEnrollmentUrl from '../../hooks/useEnrollmentUrl';

export default function Header({
  light,
  displayPhoning,
}) {
  const { enrollmentUrl } = useEnrollmentUrl();

  const SCROLL_BREAKPOINT = 45;

  const headerRef = useRef();

  const CLASS_NAME_HEADER = `header ${displayPhoning ? 'withPhoningBanner' : ''}`;

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (headerRef.current) {
        if (window.pageYOffset > SCROLL_BREAKPOINT) {
          headerRef.current.className = `${CLASS_NAME_HEADER} fixed`;
        } else {
          headerRef.current.className = CLASS_NAME_HEADER;
        }
      }
    });
  }, []);

  function closeMenu() {
    document.getElementById('menuToggle').checked = false;
  }

  return (
    <div className={CLASS_NAME_HEADER} ref={headerRef}>
      <Link to="/" onClick={closeMenu}>
        <img className="header__logo" src={Logo} alt="Dreem Health logo" />
      </Link>
      <input type="checkbox" id="menuToggle" />
      {!light && (
        <>
          <label htmlFor="menuToggle" className="burgerMenu">
            <span>{ }</span>
          </label>
          <nav className="menu">
            <Button
              text="About us"
              className="nav finer"
              link="/aboutUs/"
              onClick={closeMenu}
            />
            <Button
              text="FAQ"
              className="nav finer"
              link="/faq/"
              onClick={closeMenu}
            />
            <Button
              text="Referral form"
              className="nav finer"
              link="/Dreem Health Sleep Clinic Fax Form.pdf"
              target="_blank"
            />
            <Button
              text="Blog"
              className="nav finer"
              link="/blog/"
              onClick={closeMenu}
            />
            <Button
              text="Get started"
              className="salmon finer"
              displayArrow
              link={enrollmentUrl}
              onClick={() => {
                if (window.posthog) window.posthog.capture('cta_enroll_now', { section: 'header' });
              }}
            />
          </nav>
        </>
      )}
    </div>
  );
}

Header.defaultProps = {
  light: false,
  displayPhoning: false,
};

Header.propTypes = {
  light: PropTypes.bool,
  displayPhoning: PropTypes.bool,
};
