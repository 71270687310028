const { default: posthog } = require("posthog-js");

/* eslint-disable import/prefer-default-export */
function shouldScrollTo(previousPathname, pathname) {
  if (!previousPathname.startsWith('/blog/') || !pathname.startsWith('/blog/')) {
    return true;
  }

  return pathname.split('/').length > 4;
}

exports.shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  if (shouldScrollTo(prevRouterProps ? prevRouterProps.location.pathname : '', location.pathname)) {
    window.scrollTo(...(currentPosition || [0, 0]));
  }

  return false;
};

if (process.env.ENVIRONMENT === 'production') {
  window.posthog = posthog.init(process.env.POSTHOG_API_KEY, {
    api_host: 'https://us.posthog.com',
    session_recording: {
      maskAllInputs: true,
      maskInputFn(text, element) {
        const placeholder = element?.getAttribute('placeholder');

        if (placeholder === 'Health insurance provider') {
          return text;
        }
  
        return '*'.repeat(text.length);
      },
    },
  });
}