import React from 'react';
import './Footer.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link, StaticQuery } from 'gatsby';
import Check from '../../assets/icons/check.svg';

export default function Footer() {
  function copyToClipboard() {
    const popupCopied = document.querySelector('footer>.popupCopied');
    navigator.clipboard.writeText('hello@dreemhealth.com');
    popupCopied.classList.add('active');
    setTimeout(() => {
      popupCopied.classList.remove('active');
    }, 3000);
    if (window.posthog) window.posthog.capture('contact_footer_clicked');
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          directus {
            website_footer {
              legal_key {
                key
                title
              }
            }
          }
        } 
      `}
      render={(data) => (
        <footer>
          <div className="title-container">
            <h2>
              Change your sleep,
              change your life.
            </h2>

            <div className="background">
              <div className="wave" />
            </div>
          </div>

          <p className="overline salmon">contact us</p>
          <h5>Fax: 628-216-8120</h5>
          <a href="tel:+6507614056">
            <h5>
              Phone: (650) 761-4056
            </h5>
          </a>

          <button
            type="button"
            onClick={() => copyToClipboard()}
          >
            <h5>
              hello@dreemhealth.com
            </h5>
          </button>
          <div className="popupCopied">
            <Check />
            <p>Mail copied to clipboard!</p>
          </div>
          <div className="footer">
            <StaticImage class="logo" src="../../images/logo_new.png" alt="" />
            <div className="navigation">
              { data.directus.website_footer.map((page) => (
                <Link key={page.legal_key.title} to={`/legal/${page.legal_key.key}`}>{page.legal_key.title}</Link>
              ))}
            </div>
            <div className="certifications">
              <StaticImage class="certificat" src="../../images/footer/certification_legiscript.png" alt="" />
              <StaticImage class="certificat" src="../../images/footer/certification_achc.png" alt="" />
            </div>
          </div>
        </footer>
      )}
    />
  );
}
