import { useEffect, useState } from 'react';

export default function useEnrollmentUrl() {
  const [enrollmentUrl, setEnrollmentUrl] = useState(process.env.ENROLLMENT_URL);

  useEffect(() => {
    const nextUrl = new URL(process.env.ENROLLMENT_URL);
    const params = new URLSearchParams(window.location.search);

    if (params !== null) {
      params.forEach((value, key) => {
        nextUrl.searchParams.append(key, value);
      });
    }

    setEnrollmentUrl(nextUrl.toString());
  }, []);

  return { enrollmentUrl };
}
