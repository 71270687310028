import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

export default function Lottie(props) {
  const {
    animationData,
    height,
    width,
  } = props;
  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
    return () => anim.destroy();
  }, []);

  return (
    <div
      style={{
        height: height || 48,
        width: width || 48,
      }}
      ref={animationContainer}
    />
  );
}

Lottie.defaultProps = {
  height: null,
  width: null,
};

Lottie.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  animationData: PropTypes.object.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};
